































































import { Component, Vue } from 'vue-property-decorator'
import PaginatedTable from '@bertazzoni/back/components/PaginatedTable.vue'
import Loader from '@bertazzoni/common/src/components/Loader.vue'
import UsersService from '@bertazzoni/common/src/services/UsersService'
import { TableInfo } from '@bertazzoni/back/models/table.model'
import { User, UserType } from '@bertazzoni/common/src/models/user.model'
import ConfirmationModal from '@bertazzoni/back/components/ConfirmationModal.vue'
import ChooseEntityModal from '@bertazzoni/back/views/User/ChooseEntityModal.vue'
import { isErrorNotFound } from '@bertazzoni/common/models/user.error'

@Component({
  components: {
    PaginatedTable,
    ConfirmationModal,
    ChooseEntityModal,
    Loader
  }
})
export default class UserList extends Vue {
  private userList: Array<User> = []
  private searchField = ''
  private tableInfo: TableInfo = new TableInfo()
  private data = []
  private loading = false
  private pageLoaded = false
  private showDisableModal = false
  private showEnableModal = false
  private showChooseEntityModal = false
  private currentUser = null
  private selectedUser: { id: string; email: string }
  private showDeleteModal = false

  async created(): Promise<void> {
    this.tableInfo.getCustomTable().searchAndCreate = true
    try {
      await this.getUserList()
    } catch (e) {
      if (!isErrorNotFound(e)) {
        throw e
      }
    }
    await this.getUser()
    this.pageLoaded = true
  }

  async getUserList(): Promise<void> {
    this.loading = true
    let result
    try {
      result = await UsersService.findAllPaginated({
        limit: this.tableInfo.paginInfo.limit,
        offset: this.tableInfo.paginInfo.offset,
        searchValue: this.searchField
      })
      this.userList = result.results
    } catch (error) {
      if (isErrorNotFound(error)) {
        this.data = []
      }
      throw error
    }
    this.buildUserTable(result)
    this.loading = false
  }

  buildUserTable(result) {
    this.tableInfo.getCustomTable().columns = ['Email', 'Entity', 'Role']
    this.tableInfo.getCustomTable().displayTitle = true
    this.data = []
    this.userList.map((user: User) => {
      this.data.push({
        Email: user.email,
        Entity: user.linkedEntityId[0],
        Role: user.type,
        _id: user._id,
        title: user.email,
        enable: user.enable,
        objectType: user.type
      })
    })
    this.tableInfo.paginInfo.totalRow = result.total
  }
  async getUser(): Promise<void> {
    this.currentUser = await UsersService.findMe()
  }
  openDeleteModal(id: string, name: string) {
    this.selectedUser = { id: id, email: name }
    this.showDeleteModal = true
  }
  goUserConsult(id: string) {
    this.$router.push(`/user-consultation/${id}`)
  }
  goToUpdatePage(user: User) {
    this.$router.push(`/update-user/${user._id}`)
  }
  openDisableModal(id: string, email: string) {
    this.selectedUser = { id: id, email: email }
    this.showDisableModal = true
  }
  openEnableModal(id: string, email: string) {
    this.selectedUser = { id: id, email: email }
    this.showEnableModal = true
  }
  async newLimit(limit: number) {
    this.tableInfo.paginInfo.limit = limit
    this.newPage(0)
  }
  async newPage(offset: number) {
    this.tableInfo.paginInfo.offset = offset
    await this.getUserList()
  }
  async newSearch(searchField: string) {
    if (searchField !== this.searchField) {
      this.searchField = searchField
      this.newPage(0)
    }
  }
  async disableUser() {
    await UsersService.disableOne(this.selectedUser.id)
    this.showDisableModal = false
    await this.getUserList()
  }

  async enableUser() {
    await UsersService.enableOne(this.selectedUser.id)
    this.showEnableModal = false
    await this.getUserList()
  }
  addUser() {
    this.showChooseEntityModal = true
  }

  async getCSV() {
    const url = await UsersService.getCSV()
    const a = document.createElement('a')
    a.href = url
    a.setAttribute('download', 'exportedUsers.csv')
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
  }
  async deleteUser() {
    await UsersService.removeOne(this.selectedUser.email)
    this.showDeleteModal = false
    await this.getUserList()
  }
}
